// eslint-disable-next-line import/no-unresolved
import { useIgPrices as useIntelligemsPrices } from "@intelligems/headless/gatsby";

function useIgPrices({
  productId,
  variantId,
  originalPrice,
  originalCompareAtPrice,
  currencyCode,
}) {
  // TODO: KILL THIS COMPONENT
  const igPrices = useIntelligemsPrices({
    productId,
    variantId,
    originalPrice,
    originalCompareAtPrice,
    currencyCode,
  });

  return igPrices;
}

export default useIgPrices;
